import React, { useEffect, useState } from "react";
import Layout from "../components/layout/layout";
import Seo from "../components/seo/seo";
import HeaderAbout, { TABS } from "../components/headers/header-about";
import TextTwoCols from "../components/global/text-two-cols";
import Team from "../components/about-us/team";
import MissionVision from "../components/about-us/mission-vision";
import Location from "../components/about-us/location";
import Certification from "../components/about-us/certification";
import Office from "../components/about-us/office";
import WeAre from "../components/about-us/we-are";
import { graphql } from "gatsby";
import { useTranslation } from "react-i18next";

// import './src/scss/style.scss';

// import "../scss/style.scss";

const AboutUs = () => {
  const { t } = useTranslation();

  const textTwoColContent = {
    title: `${t("aboutUs.about.headline")}`,
    textLeftFirst: `${t("aboutUs.about.firstText")}`,
    textLeftSecond: `${t("aboutUs.about.secondText")}`,
    textLeftThird: `${t("aboutUs.about.thirdText")}`,
    textRightFirst: `${t("aboutUs.about.fourthText")}`,
    textRightSecond: `${t("aboutUs.about.fifthText")}`,
  };

  let windowHash = "";
  if (typeof window !== "undefined") {
    windowHash = window.location.hash;
  }

  const tab = Object.keys(TABS).find(
    (key) => TABS[key] === windowHash.split("#")[1]
  );

  const [activeTab, setActiveTab] = useState(TABS[tab] || TABS.ABOUT_US);

  useEffect(() => {
    setActiveTab(TABS[tab] || TABS.ABOUT_US);
  }, [windowHash]);

  const renderPanel = () => {
    switch (activeTab) {
      case TABS.ABOUT_US:
        return (
          <div className="m-tabPanel">
            <WeAre />
            <TextTwoCols
              textTwoColContent={textTwoColContent}
              cssModifer="-aboutUs"
            />
            <MissionVision />
          </div>
        );

      case TABS.TEAM:
        return <Team />;
      case TABS.MISSION_AND_VISSION:
        return <MissionVision />;
      case TABS.LOCATION:
        return <Location />;
      case TABS.CERTIFICATION:
        return <Certification />;
      case TABS.OUR_OFFICES:
        return <Office />;

      default:
        return (
          <div className="m-tabPanel">
            <WeAre />
            <TextTwoCols
              textTwoColContent={textTwoColContent}
              cssModifer="-aboutUs"
            />
            <MissionVision />
          </div>
        );
    }
  };

  return (
    <Layout>
      <Seo title="About Us" />
      <HeaderAbout activeTab={activeTab} setActiveTab={setActiveTab} />
      <div className="m-bcg"></div>
      {renderPanel()}
    </Layout>
  );
};

export default AboutUs;
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
