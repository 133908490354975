import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Image from "gatsby-image";
import { useTranslation } from "gatsby-plugin-react-i18next";

const query = graphql`
  {
    office1: file(relativePath: { eq: "images/about-us/office/office1.jpg" }) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    office2: file(relativePath: { eq: "images/about-us/office/office2.jpg" }) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    office3: file(relativePath: { eq: "images/about-us/office/office3.jpg" }) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    office4: file(relativePath: { eq: "images/about-us/office/office4.jpg" }) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    office5: file(relativePath: { eq: "images/about-us/office/office5.jpg" }) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    office6: file(relativePath: { eq: "images/about-us/office/office6.jpg" }) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    office7: file(relativePath: { eq: "images/about-us/office/office7.jpg" }) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    office8: file(relativePath: { eq: "images/about-us/office/office8.jpg" }) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    office9: file(relativePath: { eq: "images/about-us/office/office9.jpg" }) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    office10: file(
      relativePath: { eq: "images/about-us/office/office10.jpg" }
    ) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    office11: file(
      relativePath: { eq: "images/about-us/office/office11.jpg" }
    ) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    office12: file(
      relativePath: { eq: "images/about-us/office/office12.jpg" }
    ) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    office13: file(
      relativePath: { eq: "images/about-us/office/office13.jpg" }
    ) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    office14: file(
      relativePath: { eq: "images/about-us/office/office14.jpg" }
    ) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    office15: file(
      relativePath: { eq: "images/about-us/office/office15.jpg" }
    ) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    office16: file(
      relativePath: { eq: "images/about-us/office/office16.jpg" }
    ) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    office17: file(
      relativePath: { eq: "images/about-us/office/office17.jpg" }
    ) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    office18: file(
      relativePath: { eq: "images/about-us/office/office18.jpg" }
    ) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    office19: file(
      relativePath: { eq: "images/about-us/office/office19.jpg" }
    ) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    office20: file(
      relativePath: { eq: "images/about-us/office/office20.jpg" }
    ) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    office21: file(
      relativePath: { eq: "images/about-us/office/office21.jpg" }
    ) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    office22: file(
      relativePath: { eq: "images/about-us/office/office22.jpg" }
    ) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    office23: file(
      relativePath: { eq: "images/about-us/office/office23.jpg" }
    ) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    office24: file(
      relativePath: { eq: "images/about-us/office/office24.jpg" }
    ) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    office25: file(
      relativePath: { eq: "images/about-us/office/office25.jpg" }
    ) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    office26: file(
      relativePath: { eq: "images/about-us/office/office26.jpg" }
    ) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    office27: file(
      relativePath: { eq: "images/about-us/office/office27.jpg" }
    ) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    office28: file(
      relativePath: { eq: "images/about-us/office/office28.jpg" }
    ) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    office29: file(
      relativePath: { eq: "images/about-us/office/office29.jpg" }
    ) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    office30: file(
      relativePath: { eq: "images/about-us/office/office30.jpg" }
    ) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    office31: file(
      relativePath: { eq: "images/about-us/office/office31.jpg" }
    ) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    office32: file(
      relativePath: { eq: "images/about-us/office/office32.jpg" }
    ) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const Office = () => {
  const { t } = useTranslation();
  const data = useStaticQuery(query);
  const office1 = data.office1.childImageSharp.fluid;
  const office2 = data.office2.childImageSharp.fluid;
  const office3 = data.office3.childImageSharp.fluid;
  const office4 = data.office4.childImageSharp.fluid;
  const office5 = data.office5.childImageSharp.fluid;
  const office6 = data.office6.childImageSharp.fluid;
  const office7 = data.office7.childImageSharp.fluid;
  const office8 = data.office8.childImageSharp.fluid;
  const office9 = data.office9.childImageSharp.fluid;
  const office10 = data.office10.childImageSharp.fluid;
  const office11 = data.office11.childImageSharp.fluid;
  const office12 = data.office12.childImageSharp.fluid;
  const office13 = data.office13.childImageSharp.fluid;
  const office14 = data.office14.childImageSharp.fluid;
  const office15 = data.office15.childImageSharp.fluid;
  const office16 = data.office16.childImageSharp.fluid;
  const office17 = data.office17.childImageSharp.fluid;
  const office18 = data.office18.childImageSharp.fluid;
  const office19 = data.office19.childImageSharp.fluid;
  const office20 = data.office20.childImageSharp.fluid;
  const office21 = data.office21.childImageSharp.fluid;
  const office22 = data.office22.childImageSharp.fluid;
  const office23 = data.office23.childImageSharp.fluid;
  const office24 = data.office24.childImageSharp.fluid;
  const office25 = data.office25.childImageSharp.fluid;
  const office26 = data.office26.childImageSharp.fluid;
  const office27 = data.office27.childImageSharp.fluid;
  const office28 = data.office28.childImageSharp.fluid;
  const office29 = data.office29.childImageSharp.fluid;
  const office30 = data.office30.childImageSharp.fluid;
  const office31 = data.office31.childImageSharp.fluid;
  const office32 = data.office32.childImageSharp.fluid;

  return (
    <section className="m-office m-tabPanel">
      <div className="_wr">
        <div className="m-office__top">
          <h2 className="-thin">{t("aboutUs.ourOffices")}</h2>
          <span className="a-line -red"></span>
        </div>
        <div className="_w m-office__bottom">
          <div className="_12 _m4 m-office__double">
            <Image fluid={office1} className="-image" />
            <Image fluid={office2} className="-image" />
          </div>
          <div className="_12 _m8 -marginT">
            <Image fluid={office3} className="-image" />
          </div>
          <div className="_12 _m6">
            <Image fluid={office4} className="-image" />
          </div>
          <div className="_12 _m6">
            <Image fluid={office5} className="-image" />
          </div>
          <div className="_12 _m8 -marginT">
            <Image fluid={office6} className="-image" />
          </div>
          <div className="_12 _m4 m-office__double">
            <Image fluid={office7} className="-image" />
            <Image fluid={office8} className="-image" />
          </div>
          <div className="_12 _m6">
            <Image fluid={office9} className="-image" />
          </div>
          <div className="_12 _m6">
            <Image fluid={office10} className="-image" />
          </div>
          <div className="_12 _m4 m-office__double">
            <Image fluid={office11} className="-image" />
            <Image fluid={office12} className="-image" />
          </div>
          <div className="_12 _m8 -marginT">
            <Image fluid={office13} className="-image" />
          </div>
          <div className="_12 _m6">
            <Image fluid={office14} className="-image" />
          </div>
          <div className="_12 _m6">
            <Image fluid={office15} className="-image" />
          </div>
          <div className="_12 _m6">
            <Image fluid={office16} className="-image" />
          </div>
          <div className="_12 _m6">
            <Image fluid={office17} className="-image" />
          </div>
          <div className="_12 _m8 -marginT">
            <Image fluid={office18} className="-image" />
          </div>
          <div className="_12 _m4 m-office__double">
            <Image fluid={office19} className="-image" />
            <Image fluid={office20} className="-image" />
          </div>
          <div className="_12 _m6">
            <Image fluid={office21} className="-image" />
          </div>
          <div className="_12 _m6">
            <Image fluid={office22} className="-image" />
          </div>
          <div className="_12 _m6">
            <Image fluid={office23} className="-image" />
          </div>
          <div className="_12 _m6">
            <Image fluid={office24} className="-image" />
          </div>
          <div className="_12 _m4 m-office__double">
            <Image fluid={office25} className="-image" />
            <Image fluid={office26} className="-image" />
          </div>
          <div className="_12 _m8 -marginT">
            <Image fluid={office27} className="-image" />
          </div>
          <div className="_12 _m8 -marginT">
            <Image fluid={office28} className="-image" />
          </div>
          <div className="_12 _m4 m-office__double">
            <Image fluid={office29} className="-image" />
            <Image fluid={office30} className="-image" />
          </div>
          <div className="_12 _m6">
            <Image fluid={office31} className="-image" />
          </div>
          <div className="_12 _m6">
            <Image fluid={office32} className="-image" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Office;
