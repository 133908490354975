import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Image from "gatsby-image";
import { useTranslation } from "gatsby-plugin-react-i18next";

const query = graphql`
  {
    file(relativePath: { eq: "images/headers/about.png" }) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const WeAre = () => {
  const { t } = useTranslation();
  const data = useStaticQuery(query);

  return (
    <section className="m-weAre">
      <div className="_wr">
        <div className="_w m-weAre__content">
          <div className="_l7">
            <h2 className="-thin">{t("aboutUs.weAre.headline")}</h2>
            <span className="a-line -red"></span>
            <p>{t("aboutUs.weAre.firstText")}</p>
            <p>{t("aboutUs.weAre.secondText")}</p>
            <p>{t("aboutUs.weAre.thirdText")}</p>
          </div>

          <div className="_l5 m-weAre__content--image">
            <Image fluid={data.file.childImageSharp.fluid} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default WeAre;
