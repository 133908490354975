import React, { useState } from "react";
import GoogleMapReact from "google-map-react";
import { GOOGLE_MAPS_KEY } from "../../constants/map";
import { MAP_STYLES } from "../../constants/colors";
import Marker from "../markers/marker";

const InfoWindow = ({ text }) => <div>{text}</div>;

const containerStyle = {
  maxWidth: "1206px",
  height: "687px",
  margin: "0 auto",
  marginBottom: "100px",
};

const center = {
  lat: 45.267136,
  lng: 19.833549,
};

const markers = [
  {
    id: 1,
    name: "Novi Sad",
    lat: 45.267136,
    lng: 19.833549,
    location: "Novosadskog Sajma 2",
    zipCode: "21000 Novi Sad",
    fill: "#212327",
    country: "Serbia",
  },
  {
    id: 2,
    name: "Belgrade",
    lat: 44.787197,
    lng: 20.457273,
    location: "Miloja Đaka 36",
    zipCode: "111040 Belgrade",
    fill: "#212327",
    country: "Serbia",
  },
  {
    id: 4,
    name: "Baar",
    lat: 47.19625,
    lng: 8.52954,
    location: "Rathausstrasse 14",
    zipCode: "6340 Baar",
    fill: "#d22128",
    country: "Switzerland",
  },
  {
    id: 6,
    name: "Berlin",
    lat: 51.1657,
    lng: 10.4515,
    location: "Technopark 1 Strasse",
    zipCode: "10115",
    fill: "#d22128",
    country: "Germany",
  },
  {
    id: 7,
    name: "Dubai",
    lat: 25.674941464056516,
    lng: 55.77848989580949,
    location: "T2 9F 1E R. RAKEZ Amenity Center",
    zipCode: "Al Hamra Industrial Area, RAK",
    fill: "#d22128",
    country: "United Arab Emirates",
  },
  {
    id: 8,
    name: "Toronto",
    lat: 43.65107,
    lng: -79.347015,
    location: "90 Queens Wharf Road",
    zipCode: "Toronto, Ontario",
    fill: "#d22128",
    country: "Canada",
  },
  {
    id: 9,
    name: "Slovenske Konjice",
    lat: 46.33624,
    lng: 15.43006,
    location: "Industrijska cesta 8",
    zipCode: "3210 Slovenske Konjice",
    fill: "#d22128",
    country: "Slovenia",
  },
];

const Smap = (props) => {
  const defaultProps = {
    center: {
      lat: 10.99835602,
      lng: 77.01502627,
    },
    zoom: 11,
  };

  const [isShown, setIsShown] = useState(false);
  const [activeMarker, setActiveMarker] = useState({});

  const renderMarker = () => {
    const { name, location, zipCode, position, country } = activeMarker;
    return (
      <InfoWindow
        position={{
          lat: Number(position.lat),
          lng: Number(position.lng),
        }}
        onCloseClick={() => setIsShown(false)}
      >
        <div className="-mapLocation">
          <div className="-outer"></div>
          <div className="-inner"></div>
          <p className="-title"> {name} </p>
          <p> {location} </p>
          <p> {zipCode} </p>
          <p> {country} </p>
        </div>
      </InfoWindow>
    );
  };

  return (
    <div className="_wr">
      <div className="_w">
        <div
          className="m-location__map--map"
          style={{ height: "80vh", width: "100%" }}
        >
          <GoogleMapReact
            className="-map"
            mapContainerStyle={containerStyle}
            bootstrapURLKeys={{ key: GOOGLE_MAPS_KEY }}
            defaultCenter={center}
            defaultZoom={2}
            options={{ styles: MAP_STYLES }}
          >
            {props.isMarkerShown &&
              markers.map(
                ({ id, lat, lng, fill, name, location, zipCode, country }) => (
                  <Marker
                    key={id}
                    lat={lat}
                    lng={lng}
                    fill={fill}
                    location={location}
                    name={name}
                    zipCode={zipCode}
                    country={country}
                    text="mytext"
                    onClick={() => {
                      setActiveMarker({ lat, lng });
                      setIsShown(true);
                    }}
                    onMouseOver={() => {
                      setActiveMarker({ lat, lng });
                      setIsShown(true);
                    }}
                  ></Marker>
                )
              )}
            {isShown && renderMarker()}
          </GoogleMapReact>
        </div>
      </div>
    </div>
  );
};
export default Smap;
