import React from "react";

function Marker({ fill, lat, lng, name, location, zipCode, country }) {
  return (
    <div className="icon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className=" icon-tabler icon-tabler-map-pin"
        width="28"
        height="28"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="none"
        fill={fill}
        opacity="90%"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z" />
        <circle cx="12" cy="11" r="3" fill="#fff" />
      </svg>
      <div className="-infoWindow">
        <p className="-name">{name}</p>
        <p>{location}</p>
        <p>{zipCode}</p>
          <p>{country}</p>
      </div>
    </div>
  );
}

export default Marker;
