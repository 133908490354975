import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import MissionIcon from "../../assets/images/about-us/mission.svg";
import Vision from "../../assets/images/about-us/vission.svg";

const Mission = () => {
  const { t } = useTranslation();
  return (
    <section className="m-missionVision section-padding">
      <div className="_wr">
        <div className="_w">
          <div className="_12 _m6">
            <div className="m-missionVision__cards">
              <div className="m-missionVision__cards--header">
                <MissionIcon />
                <h2 className="-thin">{t("aboutUs.missionVision.mission")}</h2>
              </div>
              <p>{t("aboutUs.missionVision.missionText")}</p>
            </div>
          </div>
          <div className="_m6 ">
            <div className="m-missionVision__cards -margintop">
              <div className="m-missionVision__cards--header">
                <Vision />
                <h2 className="-thin">{t("aboutUs.missionVision.vision")}</h2>
              </div>
              <p>{t("aboutUs.missionVision.visionText")}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Mission;
