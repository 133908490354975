import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import IconZurich from "../../assets/images/city/zurich.svg";
import IconSlovenskekonjice from "../../assets/images/city/slovenskekonjice.svg";
import IconBerlin from "../../assets/images/city/berlin.svg";
import IconDubai from "../../assets/images/city/dubai.svg";
import IconToronto from "../../assets/images/city/toronto.svg";
import IconNovisad from "../../assets/images/city/novisad.svg";
import IconBelgrade from "../../assets/images/city/belgrade.svg";
import IconNis from "../../assets/images/city/nis.svg";
import IconEducation from "../../assets/images/about-us/education.svg";
import IconCulturalAfinity from "../../assets/images/about-us/cultural-afinity.svg";
import IconCommunication from "../../assets/images/about-us/communication.svg";
import IconTransport from "../../assets/images/about-us/transport.svg";
import { useTranslation } from "gatsby-plugin-react-i18next";
// import { TABS } from "../headers/header-about";
import Smap from "../map/googleMap";

const query = graphql`
  query {
    map: file(relativePath: { eq: "images/about-us/map.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const Location = () => {
  const { t } = useTranslation();
  const data = useStaticQuery(query);
  const map = data.map.childImageSharp.fluid;

  const officesContent = [
    {
      image: <IconZurich />,
      city: "Baar",
      street: "Rathausstrasse 14",
      zip: "6340 Baar",
      country: "Switzerland",
    },
    {
      image: <IconBerlin />,
      city: "Berlin",
      street: "Technopark 1 Strasse",
      zip: "10115 Berlin",
      country: "Germany",
    },
    {
      image: <IconDubai />,
      city: "Dubai",
      street: "T2 9F 1E R. RAKEZ Amenity Center",
      zip: "Al Hamra Industrial Area, RAK",
      country: "United Arab Emirates",
    },
    {
      image: <IconToronto />,
      city: "Toronto",
      street: "90 Queens Wharf Road",
      zip: "Toronto, Ontario",
      country: "Canada",
    },
    {
      image: <IconSlovenskekonjice />,
      city: "Slovenske Konjice",
      street: "Industrijska cesta 8",
      zip: "3210 Slovenske Konjice",
      country: "Slovenia",
    },
  ];

  const hubsContent = [
    {
      image: <IconBelgrade />,
      city: "Belgrade",
      street: "Miloja Đaka 36",
      zip: "11040 Belgrade",
      country: "Serbia",
    },
    {
      image: <IconNovisad />,
      city: "Novi Sad",
      street: "Novosadskog Sajma 2",
      zip: "21000 Novi Sad",
      country: "Serbia",
    },
  ];

  const advantagesContent = [
    {
      image: <IconEducation />,
      title: `${t("aboutUs.location.keyAdvantages.cards.first.headline")}`,
      text: `${t("aboutUs.location.keyAdvantages.cards.first.text")}`,
    },
    {
      image: <IconCulturalAfinity />,
      title: `${t("aboutUs.location.keyAdvantages.cards.second.headline")}`,
      text: `${t("aboutUs.location.keyAdvantages.cards.second.text")}`,
    },
    {
      image: <IconCommunication />,
      title: `${t("aboutUs.location.keyAdvantages.cards.third.headline")}`,
      text: `${t("aboutUs.location.keyAdvantages.cards.third.text")}`,
    },
    {
      image: <IconTransport />,
      title: `${t("aboutUs.location.keyAdvantages.cards.fourth.headline")}`,
      text: `${t("aboutUs.location.keyAdvantages.cards.fourth.text")}`,
    },
  ];

  return (
    <section className="m-location m-tabPanel">
      <div className="_wr">
        <div className="m-location__header">
          <h2 className="-thin">{t("aboutUs.location.headline")}</h2>
          <span className="a-line -red"></span>
          <p className="m-location__topText">{t("aboutUs.location.text")}</p>
        </div>

        <h3 className="-section">{t("aboutUs.location.offices")}</h3>
        <div className="_w m-location__cities">
          {officesContent.map(({ city, street, zip, image, country }, i) => (
            <div className="m-location__offices" key={i}>
              <div className="m-location__city">
                <div className="m-location__city--image">{image}</div>
                <span className="m-location__city--town">{city}</span>
                <span className="m-location__city--text -first">{street}</span>
                <span className="m-location__city--text -second">{zip}</span>
                <span className="m-location__city--text -second">
                  {country}
                </span>
              </div>
            </div>
          ))}
        </div>
        <h3 className="-section">{t("aboutUs.location.hubs")}</h3>
        <div className="_w m-location__cities -hubs">
          {hubsContent.map(({ city, street, zip, image, country }, i) => (
            <div className="m-location__offices" key={i}>
              <div className="m-location__city">
                <div className="m-location__city--image">{image}</div>
                <span className="m-location__city--town">{city}</span>
                <span className="m-location__city--text -first">{street}</span>
                <span className="m-location__city--text -second">{zip}</span>
                <span className="m-location__city--text -second">
                  {country}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="m-location__map">
        <div className="m-location__map--legend">
          <span className="-red">{t("aboutUs.location.offices")}</span>
          <span className="-gray">{t("aboutUs.location.hubs")}</span>
        </div>
        {/* <div className="m-location__map--image _wr">
          <Image fluid={map} />
        </div> */}
        <Smap isMarkerShown></Smap>
        <div className="-padding"></div>
      </div>
      <div className="m-location__advantages _wr">
        <div className="m-location__advantages--header">
          <h2 className="-thin -advantages">
            {t("aboutUs.location.keyAdvantages.headline")}
          </h2>
          <span className="a-line -red"></span>
        </div>
        <div className="_w m-location__advantagesBlocks">
          {advantagesContent.map(({ title, text, image }, i) => (
            <div className="m-location__card _l6" key={i}>
              <div className="m-location__advantagesBlocks--block">
                <div className="m-location__advantagesBlocks--flex">
                  <div className="m-location__advantagesBlocks--image">
                    {image}
                  </div>
                  <h3>{title}</h3>
                </div>
                <div className="m-location__advantagesBlocks--text">
                  <p>{text}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Location;
