import React, { Fragment } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";

const TextTwoCols = ({ textTwoColContent, cssModifer }) => {
  const { t } = useTranslation();

  return (
    <section
      className={`m-textTwoCols section-padding ${
        cssModifer ? cssModifer : ""
      }`}
    >
      <div className="_wr">
        <h2 className="m-textTwoCols__title -thin">
          {textTwoColContent.title}
        </h2>
        <span className="a-line -red"></span>
        <div className="m-textTwoCols__cols">
          <div className="_w">
            <div className="_l6">
              <p>{textTwoColContent.textLeftFirst}</p>
              <p>{textTwoColContent.textLeftSecond}</p>
              {textTwoColContent.textLeftThird && (
                <p>{textTwoColContent.textLeftThird}</p>
              )}
            </div>
            <div className="_l6">
              <p>{textTwoColContent.textRightFirst}</p>
              <p>{textTwoColContent.textRightSecond}</p>
            </div>
          </div>
        </div>
        {/* <div className="m-textTwoCols__btn">
          <span className="a-btn -red">
            {t('buttons.watchVideo')}
          </span>
        </div> */}
      </div>
    </section>
  );
};

export default TextTwoCols;
